import service from "@/api/service";
import { GetResponse } from "@/api/external-point-shop/response";
import { SearchRequest } from "@/api/external-point-shop/request";
import { SearchResponse } from "@/api/external-point-shop/response";

/**
 * 外部ポイント店舗一覧・検索APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-external-point-shop", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 外部ポイント店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-external-point-shop");
  return response.data as GetResponse;
}
