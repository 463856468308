import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/external-point-shop/request";
import ExternalPointShopSearch, { ListItem } from "@/store/external-point-shop/search";
import ExternalPointShopGet from "@/store/external-point-shop/get";
import Flash, { ErrorAlert } from "@/store/common/flash";


@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDialogDelete }
})
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "外部ポイント";
  headingSub = "ExternalPoints";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "外部ポイント", disabled: true },
    { text: "店舗一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    {
      label: "ポイントカード一覧",
      text: "",
      value: "action",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions: TableOptions = DEFAULT_TABLE_OPTIONS;
  // テーブルに表示するアイテムリスト
  items: ListItem[] = [];
  // 総件数
  totalCount: number = 0;

  // ------------
  /**
   * テーブルに表示するアイテムリスト
   */
   get tableItems() {
    return ExternalPointShopSearch.getItems;
  }

  /**
   * 総件数
   */
  get totalCountRecords() {
    return ExternalPointShopSearch.getTotalCount;
  }

  /**
   * 店舗名
   */
  get shopItems() {
    return ExternalPointShopGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {

    await ExternalPointShopSearch.restore(this.inputOptions as SearchRequest);
    const request = ExternalPointShopSearch.getSearchRequest;

    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();

  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ExternalPointShopSearch.clearResponse();
    await ExternalPointShopGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    this.tableOptions.page = 1;//検索後、1ページ目へ移動
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async actionClickCallback(item: ListItem) {
    await this.$router.push({
      name: "external-points-list",
      params: { shopId: String(item.id) },
      query: { shopName: item.name }
    });
  }

  /**
   * 検索処理
   */
  async search() {

    this.isLoading = true;
    const getShop = ExternalPointShopGet.get();
    const searchShop = ExternalPointShopSearch.search(this.createRequest());

    await Promise.all([getShop, searchShop]).then(() => {
      // UIDataTableコンポーネントに渡す
      this.items = this.tableItems;
      this.totalCount = this.totalCountRecords;

      this.isLoading = false;
    })

    // エラー出力
    const errorMessage = this.checkResult();
    if (errorMessage) { 
      await Flash.setErrorNow({ message: errorMessage, showReloadButton: true } as ErrorAlert);
    }
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {

    const request = this.tableOptions as SearchRequest;

    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }

    return request;
  }
  
  /**
   * 結果からエラーメッセージを取得する
   * 
   * @return string エラーメッセージ
   */
  private checkResult = (): string => {

    if (!ExternalPointShopSearch.isSuccess) {
      return ExternalPointShopSearch.getMessage;
    }
    if (!ExternalPointShopGet.isSuccess) {
      return ExternalPointShopGet.getMessage;
    }
    return '';
  }
}
